@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-quill/dist/quill.snow.css";
@import "react-quill/dist/quill.bubble.css";
@import "rsuite/DateRangePicker/styles/index.css";
@import url("https://cdn.syncfusion.com/ej2/20.3.56/bootstrap5.css");

.talentpool-menu > .MuiPaper-root {
  border: 1.5px solid #d9d9d9;
  border-radius: 6px;
}

.container {
  margin: 5%;
}

.two-line-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

ul.mentions__suggestions__list::-webkit-scrollbar {
  width: 6px;
}

ul.mentions__suggestions__list::-webkit-scrollbar-track {
  background: #fff;
}

ul.mentions__suggestions__list::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 4px;
}

.drag-and-drop-container::-webkit-scrollbar {
  height: 8px;
}

.drag-and-drop-container::-webkit-scrollbar-track {
  background: #fff;
}
.drag-and-drop-container::-webkit-scrollbar-thumb {
  background: #c2c3c4;
  border-radius: 4px;
}

.highlighted-owner {
  color: #178cf2;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px 0;
}

.unhighlighted-owner {
  color: #6b7280;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px 0;
}

.mentions {
  margin-right: 12px;
  border: none !important;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: none;
}
.mentions--singleLine .mentions__input {
  padding: 0;
  border: none;
}
.mentions--multiLine {
  width: 94%;
  min-height: 44px !important;
  max-height: 98px !important;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
}

.mentions--multiLine .mentions__control {
  font-size: 12px;
  border: none;
}

.mentions--multiLine .mentions__highlighter {
  padding: 6px;
  visibility: hidden;
}
.mentions--multiLine .mentions__input {
  padding: 10px;
  line-height: 20px;
  outline: 0;
  overflow: auto !important;
  border: 0;
}

.mentions--multiLine .mentions__input::-webkit-scrollbar {
  width: 4px;
}

.mentions--multiLine .mentions__input::-webkit-scrollbar-track {
  background: #fff;
}

.mentions--multiLine .mentions__input::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  cursor: auto;
  border-radius: 4px;
}

.mentions__suggestions__list {
  position: absolute;
  top: 12px;
  background-color: white;
  border: 2px solid #e5e7eb;
  max-height: 260px;
  overflow: auto;
}

.mentions__suggestions__item {
  padding: 10px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}

.mentions__suggestions__item:hover {
  background-color: #f3f4f6;
}

body {
  background: #f9fafb;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

body::-webkit-scrollbar {
  height: 10px;
}
body::-webkit-scrollbar-track {
  background-color: #fafafa;
}

body::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}
body::-webkit-scrollbar-thumb:horizontal {
  border-radius: 20px;
}

body::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ag-grid-cell-clickable {
  cursor: pointer;
}

.ag-grid-status-with-days {
  padding: 0 !important;
  align-items: "center" !important;
}

.ag-grid-cell-two-line > div {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 20px;
  overflow: hidden;
  color: #178cf2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ag-grid-cell-two-line > div:hover {
  text-decoration: underline;
}

.ag-checkbox-input-wrapper::after {
  color: rgb(129, 140, 248) !important;
}

.ag-checkbox-input-wrapper input {
  cursor: pointer;
}

.ag-cell {
  padding: 0 16px;
}

.ag-cell-wrapper {
  width: 100%;
}

.ag-header-cell {
  color: #131e30;
  padding: 0 12px;
  text-transform: uppercase;
}

.applicants-list-dialog {
  max-height: 226px;
  max-width: 496px !important;
  padding: 0px;
}

.applicants-list-dialog::-webkit-scrollbar {
  width: 6px;
}

.applicants-list-dialog::-webkit-scrollbar-track {
  background: #fff;
}

.applicants-list-dialog::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 4px;
}

.applications-jobs-autocomplete {
  max-height: 183.5px;
  max-width: 496px !important;
  padding: 0px;
  color: #6b7280;
  font-size: 14px;
}

.MuiAutocomplete-option {
  height: 30.48px;
  white-space: nowrap;
}

.MuiList-root {
  padding: 0px;
}

.applications-jobs-autocomplete::-webkit-scrollbar {
  width: 6px;
}

.applications-jobs-autocomplete::-webkit-scrollbar-track {
  background: #fff;
}

.applications-jobs-autocomplete::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 4px;
}

.team-members-list-dialog {
  max-height: 160px;
  max-width: 496px !important;
  padding: 0px;
}

.team-members-list-dialog::-webkit-scrollbar {
  width: 6px;
}

.team-members-list-dialog::-webkit-scrollbar-track {
  background: #fff;
}

.team-members-list-dialog::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 4px;
}

.ag-root ::-webkit-scrollbar {
  width: 8px;
}

.ag-root ::-webkit-scrollbar-button {
  display: none;
}

.ag-root ::-webkit-scrollbar-track-piece {
  background: transparent;
  width: 8px;
}

.ag-root ::-webkit-scrollbar-thumb {
  background: #ececf1;
  width: 8px;
  border-radius: 8px;
}

textarea::placeholder {
  color: #9ca3af;
  font-weight: 400;
}

.ql-custom-toolbar {
  width: 28px;
  height: 24px;
}

.ql-formats {
  position: relative;
}

.ql-toolbar .ql-custom-toolbar {
  background: none;
  border: none;
  cursor: pointer;
}

.quill-container .ql-toolbar {
  border: 1px solid #e5e7eb;
  border-radius: 6px 6px 0 0;
  box-shadow: none;
}

.quill-container .ql-container {
  height: 200px;
  border: 1px solid #e5e7eb;
  border-radius: 0 0 6px 6px;
}

.quill-error .ql-toolbar {
  border: 1px solid #fca5a5 !important;
  border-bottom: 0px solid transparent !important;
  box-shadow: -1px -1px 5px 0 #fee2e2;
}

.quill-error .ql-container {
  border: 1px solid #fca5a5 !important;
  box-shadow: 0 0 5px 2px #fee2e2;
}

.quill-focused .ql-toolbar {
  border: 1px solid #5fb2fa !important;
  border-bottom: 0px solid transparent !important;
  box-shadow: 0 0 5px 2px #c6e5ff;
}

.quill-focused .ql-container {
  border: 1px solid #5fb2fa !important;
  box-shadow: 0 0 5px 2px #c6e5ff;
}

.quill-preview .ql-editor {
  padding: 0;
}

.quill-preview .ql-editor {
  padding: 0;
}

.MuiRadio-root:hover {
  background: transparent;
}

.workflow-selector-filter .MuiInputBase-input {
  line-height: 40px;
  height: 40px !important;
}

.rs-picker-daterange-predefined {
  height: 329px !important;
  padding: 12px !important;

  div {
    margin: 4px !important;
    :hover {
      background: #f2faff !important;
      text-decoration: none !important;
    }
  }
}

.outdated-cell {
  background-color: #fffbeb;
}

.gdpr-text {
  width: 377 !important;
  white-space: unset;
}

.selected-trigger {
  box-shadow: 0px 1px 2px -1px #1018281a;
  box-shadow: 0px 1px 3px 0px #1018281a;
}

.selected-trigger:hover {
  background: #f9fafb;
}

.ql-editor::-webkit-scrollbar {
  width: 6px;
}

.ql-editor::-webkit-scrollbar-track {
  background: #fff;
}

.ql-editor::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 4px;
}

.selected-range-date-picker-button {
  color: #fff;
  background: #3498ff !important;
}

.multiple-selection-autocomplete {
  max-height: 183.5px;
  padding: 0px;
  color: #6b7280;
  font-size: 14px;
}

.multiple-selection-autocomplete .MuiAutocomplete-option[aria-selected="true"] {
  background-color: #edf7ff;
  color: #178cf2;
}

.multiple-selection-autocomplete
  .MuiAutocomplete-option[aria-selected="true"]:hover {
  background-color: #c6e5ff;
  color: #178cf2;
}

.autocomplete-multiline-tag-container {
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 142px;
  overflow: auto;
}

.autocomplete-multiline-tag-container::-webkit-scrollbar {
  width: 6px;
}

.autocomplete-multiline-tag-container::-webkit-scrollbar-track {
  background: #fff;
}

.autocomplete-multiline-tag-container::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 4px;
}

.MuiSimpleTreeView-root .MuiCollapse-root {
  padding-left: 0;
}

.company-header {
  box-shadow: 0px 1px 2px -1px #1018281a;
  box-shadow: 0px 1px 3px 0px #1018281a;
}
